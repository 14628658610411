<script>
import { required, email } from "vuelidate/lib/validators";
import {axios_get, axios_post, storeUser} from "../../../helpers/helper";


export default {
  components: {   },
  data() {

    return {
      email: null,
      password: null,
      submitted: false,
      value:null,
      show:false,
    };

  },
  computed: {
    // notification() {
    //   return this.$store ? this.$store.state.notification : null;
    // }
  },
  created() {
    // document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  methods: {

      tryToLogIn(){
          this.show=true;
          axios_post("/login",{
              email:this.email,
              password:this.password,
          },(d)=>{
            console.log(d.data)
              localStorage.setItem('token',d.data.token)
              storeUser(d.data.admin);
              axios_get("/country",{},(d)=>{
                  console.log(d)
                      this.countryData=JSON.parse(JSON.stringify(d))

                localStorage.setItem('country',JSON.stringify(d))
                    window.location.reload();
                  },(e)=>{
                      console.log("error",e);
                  },()=>{
                      console.log("finishing");
                  }
              );
          },(e)=>{
              console.log("error",e);
          },()=>{
              this.show=false;
              console.log("finishing");
          });

      }
  },
    mounted:{

    },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            CQI
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Welcome Back !</h4>
                        <p class="text-muted">Sign in to continue to CQI.</p>
                      </div>

<!--                      <b-alert-->
<!--                        variant="danger"-->
<!--                        class="mt-3"-->
<!--                        v-if="notification.message"-->
<!--                        show-->
<!--                        dismissible-->
<!--                      >{{notification.message}}</b-alert>-->

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="tryToLogIn">
                          <div class="form-group mb-4">
<!--                          <div class="form-group auth-form-group-custom mb-4">-->
<!--                            <i class="ri-mail-line auti-custom-input-icon"></i>-->
                            <label for="email">Email</label>
                            <input
                              type="email"
                              v-model="email"
                              class="form-control"
                              id="email"
                              placeholder="ex: email@gmail.com"
                              :class="{ 'is-invalid': submitted && $v.email.$error }"
                            />
                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">Email is required.</span>
                              <span v-if="!$v.email.email">Please enter valid email.</span>
                            </div>
                          </div>

                          <div class="form-group  mb-4">
<!--                            <i class="ri-lock-2-line auti-custom-input-icon"></i>-->
                            <label for="userpassword">Password</label>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="ex: password"
                              :class="{ 'is-invalid': submitted && $v.password.$error }"
                            />
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >Password is required.</div>
                          </div>

<!--                          <div class="form-group mb-4">-->
<!--&lt;!&ndash;                            <i class="ri-earth-line auti-custom-input-icon" ></i>&ndash;&gt;-->
<!--                            <label>Country</label>-->
<!--&lt;!&ndash;                            <multiselect&ndash;&gt;-->
<!--&lt;!&ndash;                                    v-model="country"&ndash;&gt;-->
<!--&lt;!&ndash;                                    type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                    class="form-control"&ndash;&gt;-->
<!--&lt;!&ndash;                                    id="country"&ndash;&gt;-->
<!--&lt;!&ndash;                            >&ndash;&gt;-->
<!--&lt;!&ndash;                            </multiselect>&ndash;&gt;-->
<!--                            <multiselect v-model="value" :options="options" :multiple="false"></multiselect>-->
<!--&lt;!&ndash;                            <div&ndash;&gt;-->
<!--&lt;!&ndash;                                    v-if="submitted && !$v.password.required"&ndash;&gt;-->
<!--&lt;!&ndash;                                    class="invalid-feedback"&ndash;&gt;-->
<!--&lt;!&ndash;                            >Country is required.</div>&ndash;&gt;-->
<!--                          </div>-->


                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                            >Remember me</label>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit" :disabled="show"
                            >
                                <div v-if="show" class="spinner-border spinner-border-sm"></div>
                                Log In</button>
                          </div>

                          <div class="mt-4 text-center">
                            <router-link tag="a" to="/forgot-password" class="text-muted">
                              <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                            </router-link>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
<!--                        <p>-->
<!--                          Don't have an account ?-->
<!--                          <router-link-->
<!--                            tag="a"-->
<!--                            to="/register"-->
<!--                            class="font-weight-medium text-primary"-->
<!--                          >Register</router-link>-->
<!--                        </p>-->
                        <p>
                          © 2022 CQI. Crafted with
                          <i class="mdi mdi-heart text-danger"></i> by GHSS
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>